* {
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}
html {
    scroll-behavior: smooth;
}
body{
    max-width: 100vw;
    overflow-x: hidden;
}