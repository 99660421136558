.btnal {
  transition: transform 0.3s ease;
}
.btnal:hover {
  color: black;
  background-color: #000;
  transform: translateX(40px);
}
.borders {
  max-width: 100vw;
  overflow-x: hidden;
}

.header-img {
  transform: rotate(270deg);
  transition: filter 0.3s ease-in-out;
  filter: grayscale(100%) contrast(1) brightness(1);
}

.header-img:hover {
  /* filter: grayscale(100%) contrast(1.5) brightness(1.2); */
  filter: none;
}
.ga {
  /* transform: rotate(270deg); */
  transition: filter 0.3s ease-in-out;
  filter: grayscale(100%) contrast(1) brightness(1);
}

.ga:hover {
  /* filter: grayscale(100%) contrast(1.5) brightness(1.2); */
  filter: none;
}
